/*
@tailwind base;

@tailwind components;
*/

.bg-black {
  --bg-opacity: 1;
  background-color: #22292F;
  background-color: rgba(34, 41, 47, var(--bg-opacity))
}

.bg-red-700 {
  --bg-opacity: 1;
  background-color: #D32F2F;
  background-color: rgba(211, 47, 47, var(--bg-opacity))
}

.bg-red {
  --bg-opacity: 1;
  background-color: #F44336;
  background-color: rgba(244, 67, 54, var(--bg-opacity))
}

.bg-orange {
  --bg-opacity: 1;
  background-color: #FF9800;
  background-color: rgba(255, 152, 0, var(--bg-opacity))
}

.bg-yellow-50 {
  --bg-opacity: 1;
  background-color: #FFFDE7;
  background-color: rgba(255, 253, 231, var(--bg-opacity))
}

.bg-yellow-100 {
  --bg-opacity: 1;
  background-color: #FFF9C4;
  background-color: rgba(255, 249, 196, var(--bg-opacity))
}

.bg-green {
  --bg-opacity: 1;
  background-color: #4CAF50;
  background-color: rgba(76, 175, 80, var(--bg-opacity))
}

.bg-blue {
  --bg-opacity: 1;
  background-color: #2196F3;
  background-color: rgba(33, 150, 243, var(--bg-opacity))
}

.bg-pink {
  --bg-opacity: 1;
  background-color: #E91E63;
  background-color: rgba(233, 30, 99, var(--bg-opacity))
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.border-red {
  --border-opacity: 1;
  border-color: #F44336;
  border-color: rgba(244, 67, 54, var(--border-opacity))
}

.border-yellow-700 {
  --border-opacity: 1;
  border-color: #FBC02D;
  border-color: rgba(251, 192, 45, var(--border-opacity))
}

.rounded-4 {
  border-radius: .4rem
}

.rounded-6 {
  border-radius: .6rem
}

.rounded-8 {
  border-radius: .8rem
}

.rounded-12 {
  border-radius: 1.2rem
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.rounded-full {
  border-radius: 9999px
}

.border-solid {
  border-style: solid
}

.border-1 {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border {
  border-width: 1px
}

.border-t-1 {
  border-top-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.border-l-1 {
  border-left-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-flex;
  display: flex
}

.inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.contents {
  display: contents
}

.hidden {
  display: none
}

.flex-row {
  -webkit-flex-direction: row;
          flex-direction: row
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap
}

.items-start {
  -webkit-align-items: flex-start;
          align-items: flex-start
}

.items-end {
  -webkit-align-items: flex-end;
          align-items: flex-end
}

.items-center {
  -webkit-align-items: center;
          align-items: center
}

.justify-start {
  -webkit-justify-content: flex-start;
          justify-content: flex-start
}

.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto
}

.flex-grow-0 {
  -webkit-flex-grow: 0;
          flex-grow: 0
}

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1
}

.flex-shrink-0 {
  -webkit-flex-shrink: 0;
          flex-shrink: 0
}

.flex-shrink {
  -webkit-flex-shrink: 1;
          flex-shrink: 1
}

.font-500 {
  font-weight: 500
}

.font-600 {
  font-weight: 600
}

.font-700 {
  font-weight: 700
}

.font-800 {
  font-weight: 800
}

.font-light {
  font-weight: 300
}

.font-normal {
  font-weight: 400
}

.font-medium {
  font-weight: 500
}

.font-semibold {
  font-weight: 600
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-20 {
  height: 2rem
}

.h-40 {
  height: 4rem
}

.h-44 {
  height: 4.4rem
}

.h-48 {
  height: 4.8rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-128 {
  height: 12.8rem
}

.h-200 {
  height: 20rem
}

.h-512 {
  height: 51.2rem
}

.h-full {
  height: 100%
}

.text-10 {
  font-size: 1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-15 {
  font-size: 1.5rem
}

.text-16 {
  font-size: 1.6rem
}

.text-17 {
  font-size: 1.7rem
}

.text-18 {
  font-size: 1.8rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-32 {
  font-size: 3.2rem
}

.text-36 {
  font-size: 3.6rem
}

.text-44 {
  font-size: 4.4rem
}

.text-48 {
  font-size: 4.8rem
}

.text-56 {
  font-size: 5.6rem
}

.text-72 {
  font-size: 7.2rem
}

.text-96 {
  font-size: 9.6rem
}

.text-128 {
  font-size: 12.8rem
}

.text-2xl {
  font-size: 2.4rem
}

.leading-none {
  line-height: 1
}

.leading-tight {
  line-height: 1.25
}

.leading-loose {
  line-height: 2
}

.list-disc {
  list-style-type: disc
}

.list-decimal {
  list-style-type: decimal
}

.m-4 {
  margin: 0.4rem
}

.m-8 {
  margin: 0.8rem
}

.m-16 {
  margin: 1.6rem
}

.m-32 {
  margin: 3.2rem
}

.mx-0 {
  margin-left: 0;
  margin-right: 0
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-12 {
  margin-top: 1.2rem;
  margin-bottom: 1.2rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.mx-20 {
  margin-left: 2rem;
  margin-right: 2rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.my-32 {
  margin-top: 3.2rem;
  margin-bottom: 3.2rem
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

.mx-48 {
  margin-left: 4.8rem;
  margin-right: 4.8rem
}

.mx-52 {
  margin-left: 5.2rem;
  margin-right: 5.2rem
}

.mx-56 {
  margin-left: 5.6rem;
  margin-right: 5.6rem
}

.my-128 {
  margin-top: 12.8rem;
  margin-bottom: 12.8rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-4 {
  margin-left: -0.4rem;
  margin-right: -0.4rem
}

.-mx-8 {
  margin-left: -0.8rem;
  margin-right: -0.8rem
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.mt-4 {
  margin-top: 0.4rem
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mb-6 {
  margin-bottom: 0.6rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.mb-8 {
  margin-bottom: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.ml-16 {
  margin-left: 1.6rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mb-28 {
  margin-bottom: 2.8rem
}

.mt-32 {
  margin-top: 3.2rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mb-40 {
  margin-bottom: 4rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.mt-64 {
  margin-top: 6.4rem
}

.mb-64 {
  margin-bottom: 6.4rem
}

.mb-80 {
  margin-bottom: 8rem
}

.mt-96 {
  margin-top: 9.6rem
}

.-mt-8 {
  margin-top: -0.8rem
}

.-mb-10 {
  margin-bottom: -1rem
}

.-mt-24 {
  margin-top: -2.4rem
}

.-mb-28 {
  margin-bottom: -2.8rem
}

.-mt-192 {
  margin-top: -19.2rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='ltr'] .ltr\:mr-48,[dir='ltr'].ltr\:mr-48 {
  margin-right: 4.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

[dir='rtl'] .rtl\:ml-48,[dir='rtl'].rtl\:ml-48 {
  margin-left: 4.8rem
}

.max-w-160 {
  max-width: 16rem
}

.max-w-288 {
  max-width: 28.8rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-sm {
  max-width: 48rem
}

.max-w-md {
  max-width: 64rem
}

.max-w-lg {
  max-width: 80rem
}

.max-w-xl {
  max-width: 96rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-full {
  max-width: 100%
}

.min-h-0 {
  min-height: 0
}

.min-h-40 {
  min-height: 4rem
}

.min-h-48 {
  min-height: 4.8rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-px {
  min-height: 1px
}

.min-h-full {
  min-height: 100%
}

.min-w-0 {
  min-width: 0
}

.min-w-20 {
  min-width: 2rem
}

.min-w-32 {
  min-width: 3.2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-48 {
  min-width: 4.8rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-xl {
  min-width: 96rem
}

.min-w-full {
  min-width: 100%
}

.opacity-50 {
  opacity: 0.5
}

.opacity-75 {
  opacity: 0.75
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-x-auto {
  overflow-x: auto
}

.overflow-y-auto {
  overflow-y: auto
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-48 {
  padding: 4.8rem
}

.p-64 {
  padding: 6.4rem
}

.p-88 {
  padding: 8.8rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.py-24 {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.py-32 {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem
}

.px-32 {
  padding-left: 3.2rem;
  padding-right: 3.2rem
}

.py-96 {
  padding-top: 9.6rem;
  padding-bottom: 9.6rem
}

.pt-0 {
  padding-top: 0
}

.pr-2 {
  padding-right: 0.2rem
}

.pb-4 {
  padding-bottom: 0.4rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pr-28 {
  padding-right: 2.8rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pb-32 {
  padding-bottom: 3.2rem
}

.pl-32 {
  padding-left: 3.2rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pt-48 {
  padding-top: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pt-64 {
  padding-top: 6.4rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pl-80 {
  padding-left: 8rem
}

.pb-224 {
  padding-bottom: 22.4rem
}

[dir='ltr'] .ltr\:pl-4,[dir='ltr'].ltr\:pl-4 {
  padding-left: 0.4rem
}

[dir='ltr'] .ltr\:pl-40,[dir='ltr'].ltr\:pl-40 {
  padding-left: 4rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:pr-4,[dir='rtl'].rtl\:pr-4 {
  padding-right: 0.4rem
}

[dir='rtl'] .rtl\:pr-40,[dir='rtl'].rtl\:pr-40 {
  padding-right: 4rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.sticky {
  position: -webkit-sticky;
  position: sticky
}

.inset-x-0 {
  right: 0;
  left: 0
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='ltr'] .ltr\:left-0,[dir='ltr'].ltr\:left-0 {
  left: 0
}

[dir='rtl'] .rtl\:right-0,[dir='rtl'].rtl\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

.resize {
  resize: both
}

.shadow-1 {
  box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
}

.shadow-2xl {
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25)
}

.hover\:shadow-2:hover {
  box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)
}

.hover\:shadow-5:hover {
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.text-justify {
  text-align: justify
}

.text-black {
  --text-opacity: 1;
  color: #22292F;
  color: rgba(34, 41, 47, var(--text-opacity))
}

.text-white {
  --text-opacity: 1;
  color: #FFF;
  color: rgba(255, 255, 255, var(--text-opacity))
}

.text-gray-800 {
  --text-opacity: 1;
  color: #424242;
  color: rgba(66, 66, 66, var(--text-opacity))
}

.text-red {
  --text-opacity: 1;
  color: #F44336;
  color: rgba(244, 67, 54, var(--text-opacity))
}

.text-green {
  --text-opacity: 1;
  color: #4CAF50;
  color: rgba(76, 175, 80, var(--text-opacity))
}

.text-blue {
  --text-opacity: 1;
  color: #2196F3;
  color: rgba(33, 150, 243, var(--text-opacity))
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.hover\:underline:hover {
  text-decoration: underline
}

.tracking-widest {
  letter-spacing: 0.1em
}

.visible {
  visibility: visible
}

.invisible {
  visibility: hidden
}

.whitespace-no-wrap {
  white-space: nowrap
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-1 {
  width: 0.1rem
}

.w-16 {
  width: 1.6rem
}

.w-20 {
  width: 2rem
}

.w-24 {
  width: 2.4rem
}

.w-32 {
  width: 3.2rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-72 {
  width: 7.2rem
}

.w-80 {
  width: 8rem
}

.w-96 {
  width: 9.6rem
}

.w-128 {
  width: 12.8rem
}

.w-160 {
  width: 16rem
}

.w-192 {
  width: 19.2rem
}

.w-224 {
  width: 22.4rem
}

.w-256 {
  width: 25.6rem
}

.w-288 {
  width: 28.8rem
}

.w-auto {
  width: auto
}

.w-xs {
  width: 32rem
}

.w-xl {
  width: 96rem
}

.w-px {
  width: 1px
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-99 {
  z-index: 99
}

.z-999 {
  z-index: 999
}

.z-9999 {
  z-index: 9999
}

.transform {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  -webkit-transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
          transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y))
}

.transition {
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, -webkit-transform
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0
  }
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1)
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1)
  }
}

@media (min-width: 600px) {

  .sm\:flex {
    display: -webkit-flex;
    display: flex
  }

  .sm\:hidden {
    display: none
  }

  .sm\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row
  }

  .sm\:items-center {
    -webkit-align-items: center;
            align-items: center
  }

  .sm\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start
  }

  .sm\:justify-center {
    -webkit-justify-content: center;
            justify-content: center
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:h-360 {
    height: 36rem
  }

  .sm\:text-20 {
    font-size: 2rem
  }

  .sm\:text-56 {
    font-size: 5.6rem
  }

  .sm\:mx-8 {
    margin-left: 0.8rem;
    margin-right: 0.8rem
  }

  .sm\:mx-12 {
    margin-left: 1.2rem;
    margin-right: 1.2rem
  }

  .sm\:ml-0 {
    margin-left: 0
  }

  .sm\:mb-12 {
    margin-bottom: 1.2rem
  }

  .sm\:mt-16 {
    margin-top: 1.6rem
  }

  .sm\:mt-32 {
    margin-top: 3.2rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:min-w-0 {
    min-width: 0
  }

  .sm\:p-16 {
    padding: 1.6rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:p-64 {
    padding: 6.4rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .sm\:py-32 {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem
  }

  .sm\:pt-0 {
    padding-top: 0
  }

  [dir='ltr'] .sm\:ltr\:text-left,[dir='ltr'].sm\:ltr\:text-left {
    text-align: left
  }

  [dir='rtl'] .sm\:rtl\:text-right,[dir='rtl'].sm\:rtl\:text-right {
    text-align: right
  }

  .sm\:w-48 {
    width: 4.8rem
  }

  .sm\:w-160 {
    width: 16rem
  }

  .sm\:w-auto {
    width: auto
  }

  .sm\:w-1\/2 {
    width: 50%
  }

  .sm\:w-1\/3 {
    width: 33.333333%
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: -webkit-flex;
    display: flex
  }

  .md\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row
  }

  .md\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start
  }

  .md\:items-end {
    -webkit-align-items: flex-end;
            align-items: flex-end
  }

  .md\:items-center {
    -webkit-align-items: center;
            align-items: center
  }

  .md\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start
  }

  .md\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%
  }

  .md\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0
  }

  .md\:h-48 {
    height: 4.8rem
  }

  .md\:h-64 {
    height: 6.4rem
  }

  .md\:text-18 {
    font-size: 1.8rem
  }

  .md\:text-32 {
    font-size: 3.2rem
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:my-0 {
    margin-top: 0;
    margin-bottom: 0
  }

  .md\:mx-4 {
    margin-left: 0.4rem;
    margin-right: 0.4rem
  }

  .md\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .md\:max-w-3xl {
    max-width: 128rem
  }

  .md\:min-h-64 {
    min-height: 6.4rem
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-16 {
    padding: 1.6rem
  }

  .md\:p-24 {
    padding: 2.4rem
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:px-0 {
    padding-left: 0;
    padding-right: 0
  }

  .md\:py-6 {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem
  }

  .md\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .md\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .md\:pt-128 {
    padding-top: 12.8rem
  }

  [dir='ltr'] .md\:ltr\:pr-32,[dir='ltr'].md\:ltr\:pr-32 {
    padding-right: 3.2rem
  }

  [dir='rtl'] .md\:rtl\:pl-32,[dir='rtl'].md\:rtl\:pl-32 {
    padding-left: 3.2rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:w-64 {
    width: 6.4rem
  }

  .md\:w-320 {
    width: 32rem
  }

  .md\:w-1\/2 {
    width: 50%
  }

  .md\:w-1\/4 {
    width: 25%
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:-mx-4 {
    margin-left: -0.4rem;
    margin-right: -0.4rem
  }

  .lg\:px-4 {
    padding-left: 0.4rem;
    padding-right: 0.4rem
  }

  .lg\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .lg\:px-20 {
    padding-left: 2rem;
    padding-right: 2rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  .lg\:w-1\/2 {
    width: 50%
  }
}

@media print {

  .print\:text-16 {
    font-size: 1.6rem
  }

  .print\:mx-16 {
    margin-left: 1.6rem;
    margin-right: 1.6rem
  }

  .print\:mt-0 {
    margin-top: 0
  }

  .print\:mb-0 {
    margin-bottom: 0
  }

  .print\:mb-12 {
    margin-bottom: 1.2rem
  }

  .print\:p-0 {
    padding: 0
  }

  .print\:p-8 {
    padding: 0.8rem
  }

  .print\:px-16 {
    padding-left: 1.6rem;
    padding-right: 1.6rem
  }

  .print\:shadow-none {
    box-shadow: none
  }

  .print\:w-60 {
    width: 6rem
  }

  .print\:w-full {
    width: 100%
  }
}